<template>
  <div>
    <div
      class="relative mt-n4 mb-4 mx-n4"
      style="height: 16px; overflow: hidden; border-radius: 4px 4px 0 0"
    >
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        absolute
        color="secondary darken-2"
      />
    </div>

    <!-- Top part above table -->
    <v-row>
      <!-- Left side -->
      <v-col cols="12" md="6">
        <!-- Filter by type -->
        <div class="my-2">
          <!-- Title -->
          <div class="d-flex">
            <v-icon color="primary lighten-3" size="16">mdi-filter</v-icon>
            <div class="text-body-2 primary--text text--lighten-2 pl-1">
              {{ $t('intents.filterTypeLabel') }}
            </div>
          </div>

          <ChipFilter
            v-if="!isLoading"
            :options="filterTypeItems"
            disableNoAmount
            @onChange="changeFilterType"
          />
        </div>

        <!-- Filter by status -->
        <div class="my-2">
          <div class="d-flex">
            <v-icon color="primary lighten-3" size="16">mdi-filter</v-icon>
            <div class="text-body-2 primary--text text--lighten-2 pl-1">
              {{ $t('intents.filterStatusLabel') }}
            </div>
          </div>
          <ChipFilter
            v-if="!isLoading"
            :options="filterStatusItems"
            @onChange="changeFilterStatus"
          />
        </div>

        <!-- Hidden: Search on small devices -->
        <div class="my-2 hidden-md-and-up">
          <div class="d-flex">
            <v-icon color="primary lighten-3" size="16">mdi-filter</v-icon>
            <div class="text-body-2 primary--text text--lighten-2 pl-1">
              {{ useAISearch ? $t('intents.filterSearchLabelAI') : $t('intents.filterSearchLabel') }}
            </div>
          </div>

          <div style="width: 400px; height: 48px" class="d-flex gap-6 items-center py-1 font-weight-regular">
            <v-text-field
              v-model="searchValue"
              :placeholder="useAISearch ? $t('intents.filterSearchPlaceholderAI') : $t('intents.filterSearchPlaceholder')"
              single-line
              outlined
              dense
              :prepend-inner-icon="searchIcon"
              hide-details
              @keydown.enter="handleSearchKeyDown"
              @click:prepend-inner="handleSearchbarIconClick"
              :readonly="isLoading"
            />

            <v-switch
              v-if="canUseAISearch"
              :label="$t('intents.aiSearch')"
              inset
              color="rgb(87,0,208)"
              v-model="useAISearch"
            />
          </div>
        </div>
      </v-col>

      <!-- Right side - Tag filters -->
      <v-col v-if="allTags?.length" cols="12" md="6" class="d-flex flex-col" :class="{'items-end': isSmallSize}">
        <!-- Title -->
        <div class="d-flex my-2">
          <v-icon color="primary lighten-3" size="16">mdi-filter</v-icon>
          <div class="text-body-2 primary--text text--lighten-2 pl-1">
            {{ $t('intents.tags.filterLabel') }}
          </div>
        </div>

        <v-chip-group
          :key="`${showAllTags}-${activeTagIndexes.join('-')}`"
          v-model="activeTagIndexes"
          class="mb-4 justify-group-end"
          column
          multiple
          active-class="primary lighten-2"
        >
          <v-chip
            v-for="tag in showAllTags ? allTags : popularTags"
            :key="tag"
            color="primary lighten-3"
            :outlined="!activeTags.includes(tag)"
          >
            <span>{{ tag }}</span>
            <span class="pl-2" style="opacity: 0.6;">({{ tagCounts[tag] }})</span>
          </v-chip>

          <v-chip
            v-if="showAllTags"
            color="primary lighten-3"
            :outlined="!activeTags.includes(null)"
            :key="null"
          >
            <em>{{ $t('intents.tags.untagged') }}</em>
          </v-chip>

          <!-- Show all -->
          <v-chip
            v-if="!showAllTags"
            outlined
            class="text-body-2 primary--text text--lighten-2"
            style="width: fit-content"
            @click.once="enableAllTags"
          >
            <v-icon color="primary lighten-3">
              mdi-chevron-down
            </v-icon>
            {{ $t('intents.tags.showAll') }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <!-- Table of intents -->
    <v-data-table
      v-if="!isLoading && allIntents && tableOptions"
      :items="allIntentsFiltered"
      :headers="tableHeaders"
      :options="tableOptions"
      :page="page"
      :item-class="
        (item) =>
          selectedIntent && item.intent === selectedIntent.intent
            ? 'item item-active'
            : 'item'
      "
      @click:row="selectIntent"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [20, 50, -1],
      }"
      :hide-default-footer="allIntentsFiltered && allIntentsFiltered.length < 20"
      :no-data-text="$t('intents.noDataFound')"
      @update:options="setTableOptions"
      @update:page="setCurrentPage"
      :custom-sort="customSort"
    >
      <template v-slot:[`header.status`]>
        <div
          v-if="filterChannelItems && filterChannelItems.length > 1"
          class="py-2"
        >
          <v-select
            outlined
            :label="$t('intents.channelDropdownLabel')"
            class="d-inline-block text-body-2"
            :items="filterChannelItems"
            :value="selectedFilterChannel"
            @change="changeFilterChannel"
            item-text="displayName"
            item-value="channelId"
            dense
            hide-details
          />
        </div>
      </template>

      <template #[`header.displayName`] class="d-flex">

        <div class="search-container items-center gap-2">

          <v-data-table-header
            :headers="[{text: $t('intents.headers.name'), sortable: false}]"
            class="pr-4"
          />

          <div class="inner-search-container justify-center items-center gap-6 grow hidden-sm-and-down">
            <v-switch
              v-if="canUseAISearch"
              :label="$t('intents.aiSearch')"
              inset
              color="rgb(87,0,208)"
              v-model="useAISearch"
              @click.stop
            />

            <div @click.stop style="width: 320px; height: 48px" class="py-1 font-weight-regular">
              <v-text-field
                v-model="searchValue"
                :placeholder="useAISearch ? $t('intents.filterSearchPlaceholderAI') : $t('intents.filterSearchPlaceholder')"
                single-line
                outlined
                dense
                :prepend-inner-icon="searchIcon"
                hide-details
                @click.stop
                @click:prepend-inner="handleSearchbarIconClick"
                @keydown.enter="handleSearchKeyDown"
                :readonly="isLoading"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-icon
          size="20"
          class="mr-2"
          :color="item.type && typeIcons[item.type].iconColor"
        >
         {{ intentIcon(item) }}
        </v-icon>
      </template>

      <template v-slot:[`item.displayName`]="{ item }">
        <div class="py-2" :id="item.intent">
          <span
            class="font-weight-bold primary--text text--lighten-3 d-inline-block mr-2"
            v-if="item.type === typeTypes.SMALLTALK"
          >
            {{ $t('intent.smalltalk.label') }}:
          </span>
          <span
            class="font-weight-bold primary--text text--lighten-3 d-inline-block mr-2"
            v-if="item.type === typeTypes.AI_SUGGESTION"
          >
            {{ $t('intent.aiSuggestion.label') }}:
          </span>
          <span
            class="font-weight-bold primary--text text--lighten-3 d-inline-block mr-2"
            v-if="item.type === typeTypes.AI_TEMPLATE"
          >
            {{ $t('intent.aiTemplate.label') }}:
          </span>
          <span class="text-body-1">
            {{ displayName(item.intent, user.language) }}
          </span>
          <v-icon v-if="item.inReview"></v-icon>

          <div
            class="text-body-2 grey--text"
            v-if="$vuetify.breakpoint.lgAndUp"
          >
            {{
              description(item.intent, user.language) &&
              description(item.intent, user.language).length > 0
                ? description(item.intent, user.language)
                : ''
            }}
          </div>

          <div v-if="item.tags?.length" class="d-flex gap-1 flex-wrap pt-2 pb-1">
            <v-chip
              v-for="tag in item.tags"
              :key="tag"
              color="primary lighten-3"
              :outlined="!activeTags.includes(tag)"
              small
            >
              {{ tag }}
            </v-chip>
          </div>

        </div>
      </template>

      <template v-slot:[`item.relevanceFrequent`]="{ item }">
        <IntentsRelevanceBar
          v-if="
            item.type !== typeTypes.SMALLTALK &&
            item.status !== statusTypes.IN_REVIEW &&
            item.suggested !== true
          "
          :intent="item"
        />
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex justify-space-between">
          <div
            v-if="item.type !== typeTypes.AI_SUGGESTION"
            class="d-flex align-center"
          >
            <template v-if="item.status">
              <v-icon
                size="20"
                class="mr-2"
                :color="statusIcons[item.status].iconColor"
              >
                {{ statusIcons[item.status].icon }}
              </v-icon>
              <span class="text-body-2">
                {{ $t(`intentStatus.${item.status}.label`) }}
              </span>
            </template>
            <template v-else>
              <span class="text-body-2 primary--text">...</span>
            </template>
          </div>
          <div v-else />
          <div>
            <!-- TODO - Wird erst kommen, wenn Deploy Status für Intent vorhanden ist -->
            <!-- <BtnSquare
              class="ml-4"
              :icon="deployIcons[item.deployStatus].icon"
              :colorIcon="deployIcons[item.deployStatus].iconColor"
              @click="startDeployment"
            /> -->
            <BtnSquare
              v-if="item.type !== typeTypes.AI_SUGGESTION"
              class="ml-4"
              icon="edit"
              @click="goToEdit(item.intent)"
              data-cy="edit-intent-btn"
            />
            <!-- TODO release 2.4.5 -->
            <BtnSquare
              v-if="item.type === typeTypes.AI_SUGGESTION && isCreateBtnShown"
              class="ml-4"
              icon="mdi-plus-box"
              colorIcon="secondary darken-2"
              @click="openCreateDialog(item)"
            />
          </div>
        </div>
      </template>
    </v-data-table>
    <IntentsDialogSuggestion
      :isOpen="isSuggestionDialogOpen"
      @onClose="isSuggestionDialogOpen = false"
      :intent="suggestedIntent"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChipFilter from '@/components/common/ChipFilter.vue';
import IntentUtils from '@/utils/intent';
import BtnSquare from '@/components/common/BtnSquare.vue';
import IntentsDialogSuggestion from '@/components/intents/IntentsDialogSuggestion.vue';
import IntentsRelevanceBar from '@/components/intents/IntentsRelevanceBar.vue';
import { TrackingService } from '@/services/tracking';
import { onKeyStroke, useActiveElement } from '@vueuse/core';
import IntentService from '@/services/intents';
import SparkleLoader from '@/components/common/SparkleLoader.vue';
import Fuse from 'fuse.js';

export default {
  name: 'IntentsList',
  components: {
    SparkleLoader,
    ChipFilter,
    BtnSquare,
    IntentsRelevanceBar,
    IntentsDialogSuggestion,
  },
  data() {
    return {
      isSuggestionDialogOpen: false,
      selectedIntent: null,
      suggestedIntent: null,
      typeTypes: IntentUtils.typeTypes,
      statusTypes: IntentUtils.statusTypes,
      deployStatusTypes: IntentUtils.deployStatusTypes,
      typeIcons: IntentUtils.typeIcons,
      statusIcons: IntentUtils.statusIcons,
      deployIcons: IntentUtils.deployIcons,
      isTableUpdated: false,
      page: 1,
      isLoading: true, // initially true, until intents are loaded
      scrollToTarget: null,
      activeElement: useActiveElement(),
      // Filter and sort intents using AI Search; search value
      useAISearch: false,
      searchValue: '',
      fuse: null, // Fuse JS instance for search
      searchResults: [],
      usingSearchResults: false,
      showAllTags: false,
      activeTagIndexes: [],
    };
  },
  mounted() {
    if (this.currentIntent) {
      this.selectIntent(this.currentIntent);
    }

    setTimeout(() => {
      this.page = this.currentPage;
    }, 500);
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'getBot']),
    ...mapGetters('intents', [
      'intent',
      'intents',
      'suggested',
      'smalltalks',
      'isOriginIntent',
      'currentIntent',
      'displayName',
      'description',
      'isAutoTranslated',
    ]),
    ...mapGetters('intentsList', [
      'selectedFilterChannel',
      'selectedFilterType',
      'selectedFilterStatus',
      'tableOptions',
      'currentPage',
    ]),
    ...mapGetters('auth', ['isAdmin', 'user', 'isSimpleEdit', 'isOwner']),
    ...mapGetters('bots', ['currentChannels', 'getBotSettings']),
    isSmallSize() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    activeTags() {
      // When showing all tags, include `null` at the end to allow filtering intents without tags
      const arr = this.showAllTags ? this.allTags.concat([null]) : this.popularTags;
      return this.activeTagIndexes.map(idx => arr[idx]);
    },
    /**
     * Popular tags seen the most times.
     * Sorted by times seen.
     * @returns {string[]}
     */
    popularTags() {
      // Return max 5 tags, sorted by most popular first
      return Object.keys(this.tagCounts)
        .sort((a, b) => this.tagCounts[b] - this.tagCounts[a])
        .slice(0, 10);
    },
    /**
     * Returns a running list of how many times a tag is seen
     * @returns {Record<string, number>}
     */
    tagCounts() {
      const tags = {};

      for (let i=0; i<this.intents.length; i++) {
        if (!this.intents[i]?.tags?.length) continue;

        for (let n=0; n<this.intents[i].tags.length; n++) {
          const tag = this.intents[i].tags[n];
          if (tag in tags) tags[tag]++;
          else tags[tag] = 1;
        }
      }

      return tags;
    },
    /**
     * All possible tags. Sorted alphabetically
     * @returns {any[]}
     */
    allTags() {
      const tags = new Set();

      this.intents.forEach((i) => {
        if (i.tags) {
          i.tags.forEach((t) => tags.add(t));
        }
      });

      return Array.from(tags).sort((a,b) => a.localeCompare(b));
    },
    quota() {
      return this.getBotSettings(this.currentBotId)?.quota || null;
    },
    filterChannelItems() {
      let channels = this.currentChannels.filter((c) => !c.blocked);

      if (channels?.length === 1) {
        if (this.selectedFilterChannel !== channels[0].channelId) {
          this.setSelectedFilterChannel(channels[0].channelId);
        }
        return channels[0];
      }

      if (
        this.selectedFilterChannel !== 'all' &&
        !channels.find((c) => c.channelId === this.selectedFilterChannel)
      ) {
        this.setSelectedFilterChannel('all');
      }

      return [
        {
          displayName: this.$t('intents.details.channels.all'),
          channelId: 'all',
        },
        ...channels,
      ];
    },
    filterTypeActivated() {
      return !!this.filterTypeItems.find((i) => i.isActive);
    },
    filterTypeDefault() {
      return this.filterTypeItems.find(
        (i) => i.value === IntentUtils.typeTypes.DEFAULT
      );
    },
    filterTypeSmalltalks() {
      return this.filterTypeItems.find(
        (i) => i.value === IntentUtils.typeTypes.SMALLTALK
      );
    },
    filterTypeSuggestions() {
      return this.filterTypeItems.find(
        (i) => i.value === IntentUtils.typeTypes.AI_SUGGESTION
      );
    },
    filterStatusActivated() {
      return !!this.filterStatusItems.find((i) => i.isActive);
    },
    filterStatusAllLive() {
      return this.filterStatusItems.find(
        (i) => i.value === this.statusTypes.ALL_LIVE
      );
    },
    filterStatusAllNotLive() {
      return this.filterStatusItems.find(
        (i) => i.value === this.statusTypes.ALL_NOT_LIVE
      );
    },
    filterStatusLive() {
      return this.filterStatusItems.find(
        (i) => i.value === this.statusTypes.LIVE
      );
    },
    filterStatusPreview() {
      return this.filterStatusItems.find(
        (i) => i.value === this.statusTypes.PREVIEW
      );
    },
    filterStatusDeactivated() {
      return this.filterStatusItems.find(
        (i) => i.value === this.statusTypes.DEACTIVATED
      );
    },
    filterStatusRedirected() {
      return this.filterStatusItems.find(
        (i) => i.value === this.statusTypes.REDIRECTED
      );
    },
    filterStatusInReview() {
      return this.filterStatusItems.find(
        (i) => i.value === this.statusTypes.IN_REVIEW
      );
    },
    allIntents() {
      if (this.isLoading) return [];
      let intents = [];

      // intents
      if (
        this.intents?.length &&
        (!this.filterTypeActivated || this.filterTypeDefault.isActive)
      ) {
        intents = this.intents
          .filter((i) => i.template !== 'ProductAdvisor')
          .map((i) => ({
          ...i,
          type: this.isOriginIntent(i.intent)
            ? IntentUtils.typeTypes.AI_TEMPLATE
            : IntentUtils.typeTypes.DEFAULT,
        }));
      }

      // suggested
      if (
        this.suggested?.length &&
        (!this.filterTypeActivated || this.filterTypeSuggestions.isActive)
      ) {
        const suggestedIntents = this.suggested.map((i) => ({
          ...i,
          type: IntentUtils.typeTypes.AI_SUGGESTION,
        }));
        intents = [...intents, ...suggestedIntents];
      }

      // smalltalks
      if (
        this.smalltalks?.length &&
        (!this.filterTypeActivated || this.filterTypeSmalltalks.isActive)
      ) {
        const smalltalkIntents = this.smalltalks.map((i) => ({
          ...i,
          type: IntentUtils.typeTypes.SMALLTALK,
        }));
        intents = [...intents, ...smalltalkIntents];
      }

      const newIntents = [];

      intents.forEach((i) => {
        const intent = this.parseIntent(i);

        // Ignore according status filter
        if (this.filterStatusActivated) {
          if (
            !intent.status ||
            intent.type === IntentUtils.typeTypes.AI_SUGGESTION
          ) {
            return;
          }

          if (
            (!this.filterStatusAllLive?.isActive &&
              intent.status === this.statusTypes.ALL_LIVE) ||
            (!this.filterStatusAllNotLive?.isActive &&
              intent.status === this.statusTypes.ALL_NOT_LIVE) ||
            (!this.filterStatusInReview?.isActive &&
              intent.status === this.statusTypes.IN_REVIEW) ||
            (!this.filterStatusLive?.isActive &&
              intent.status === this.statusTypes.LIVE) ||
            (!this.filterStatusPreview?.isActive &&
              intent.status === this.statusTypes.PREVIEW) ||
            (!this.filterStatusDeactivated?.isActive &&
              intent.status === this.statusTypes.DEACTIVATED) ||
            (!this.filterStatusRedirected?.isActive &&
              intent.status === this.statusTypes.REDIRECTED)
          ) {
            return;
          }
        }

        newIntents.push(intent);
      });

      return newIntents;
    },
    allIntentsFiltered() {
      if (!this.usingSearchResults && !this.activeTags.length) return this.allIntents;
      let candidates = this.allIntents;

      // Chips filter is applied first
      if (this.activeTags.length) {
        candidates = candidates.filter(i => {
          if (i.tags?.some(t => this.activeTags.includes(t))) return true;

          // If `null` is in the list of tags, we want to include intents without tags
          if (this.activeTags.includes(null) && !i.tags?.length) return true;

          return false;
        });
      }

      // Then filter by search on top
      if (this.usingSearchResults) {
        candidates = candidates.filter(i => this.searchResults.includes(i.intent));
      }

      return candidates;
    },
    filterTypeItems() {
      let items = [
        {
          name: this.$t('intents.filters.aiIntents'),
          value: IntentUtils.typeTypes.DEFAULT,
          icon: 'mdi-folder-text',
          amount: this.intents?.filter(i => i.template !== 'ProductAdvisor').length || 0,
        },
        {
          name: this.$t('intents.filters.aiSmalltalk'),
          value: IntentUtils.typeTypes.SMALLTALK,
          icon: 'coffee',
          amount: this.smalltalks?.length || 0,
        },
        {
          name: this.$t('intents.filters.aiSuggestions'),
          value: IntentUtils.typeTypes.AI_SUGGESTION,
          icon: 'auto_awesome',
          iconColor: 'secondary darken-2',
          amount: this.suggested?.length || 0,
        },
      ];

      // Get active status from chip-group
      items = items.map((item, index) => {
        item.isActive = this.selectedFilterType.includes(index);
        return item;
      });

      return items;
    },
    filterStatusItems() {
      let items = [
        {
          name: this.$t('intentStatus.inReview.label'),
          value: this.statusTypes.IN_REVIEW,
          icon: this.statusIcons[this.statusTypes.IN_REVIEW].icon,
          iconColor: this.statusIcons[this.statusTypes.IN_REVIEW].iconColor,
        },
        // TODO (Kommt erst, wenn die Info ob Intent deployed werden kann, vorhanden ist.)
        // {
        //   name: 'Änderungen zum Bereitstellen',
        //   value: this.deployStatusTypes.CHANGED,
        //   icon: this.deployIcons[this.deployStatusTypes.CHANNELS_CHANGED].icon,
        //   iconColor:
        //     this.deployIcons[this.deployStatusTypes.CHANNELS_CHANGED].iconColor,
        // },
      ];

      if (
        this.currentChannels.filter((c) => !c.blocked).length > 1 &&
        this.selectedFilterChannel === 'all'
      ) {
        items = [
          ...items,
          {
            name: this.$t('intentStatus.allLive.label'),
            value: this.statusTypes.ALL_LIVE,
            icon: this.statusIcons[this.statusTypes.ALL_LIVE].icon,
            iconColor: this.statusIcons[this.statusTypes.ALL_LIVE].iconColor,
          },
          {
            name: this.$t('intentStatus.allNotLive.label'),
            value: this.statusTypes.ALL_NOT_LIVE,
            icon: this.statusIcons[this.statusTypes.ALL_NOT_LIVE].icon,
            iconColor:
              this.statusIcons[this.statusTypes.ALL_NOT_LIVE].iconColor,
          },
        ];
      } else {
        items = [
          ...items,
          {
            name: this.$t('intentStatus.live.label'),
            value: this.statusTypes.LIVE,
            icon: this.statusIcons[this.statusTypes.LIVE].icon,
            iconColor: this.statusIcons[this.statusTypes.LIVE].iconColor,
          },
          {
            name: this.$t('intentStatus.preview.label'),
            value: this.statusTypes.PREVIEW,
            icon: this.statusIcons[this.statusTypes.PREVIEW].icon,
            iconColor: this.statusIcons[this.statusTypes.PREVIEW].iconColor,
          },
          {
            name: this.$t('intentStatus.deactivated.label'),
            value: this.statusTypes.DEACTIVATED,
            icon: this.statusIcons[this.statusTypes.DEACTIVATED].icon,
            iconColor: this.statusIcons[this.statusTypes.DEACTIVATED].iconColor,
          },
          {
            name: this.$t('intentStatus.redirected.label'),
            value: this.statusTypes.REDIRECTED,
            icon: this.statusIcons[this.statusTypes.REDIRECTED].icon,
            iconColor: this.statusIcons[this.statusTypes.REDIRECTED].iconColor,
          },
        ];
      }

      items = items.map((item, index) => {
        item.isActive = this.selectedFilterStatus.includes(index);
        return item;
      });

      return items;
    },
    tableHeaders() {
      return [
        {
          text: this.$t('intents.headers.type'),
          value: 'type',
          width: '75px',
          sortable: false,
        },
        {
          text: this.$t('intents.headers.name'),
          value: 'displayName',
          sortable: !this.usingSearchResults && !this.useAISearch,
        },
        {
          text: this.usingSearchResults
            ? '' // this.$t('intents.aiRelevanceSort')
            : this.$t('intents.headers.relevance'),
          value: 'relevanceFrequent',
          width: '110px',
          sortable: !this.usingSearchResults && !this.useAISearch,
        },
        {
          text: this.$t('intents.headers.status'),
          value: 'status',
          sortable: false,
          width: '220px',
        },
      ];
    },
    isCreateBtnShown() {
      return (
        this.isAdmin ||
        ((this.isOwner || this.isSimpleEdit) &&
          this.quota &&
          this.quota.intents)
      );
    },
    canUseAISearch() {
      const bot = this.getBot(this.currentBotId);
      return !!bot?.nlpFeatures?.includes("k2index");
    },
    searchIcon() {
      if (this.usingSearchResults) return 'close';
      if (this.useAISearch) return 'auto_awesome';
      return 'sort';
    },
  },
  created() {
    this.init();
    this.trackIt('view');

    onKeyStroke('e', () => {
      if (!this.activeElement.className.includes('moin-app')) return;
      this.goToEdit(this.selectedIntent?.intent);
    });

    onKeyStroke('t', () => {
      if (!this.activeElement.className.includes('moin-app')) return;
      this.goToTraining(this.selectedIntent?.intent)
    });

    if (this.allTags.length < 10) {
      this.enableAllTags();
    }
  },
  methods: {
    async init() {
      this.isLoading = false;

      await new Promise((resolve) => setTimeout(resolve, 100));

      if (this.scrollToTarget) {
        this.scrollToItem(this.scrollToTarget);
      }
    },
    setSelectedFilterChannel(value) {
      this.$store.commit('intentsList/setValue', {
        key: 'selectedFilterChannel',
        value,
      });
    },
    setSelectedFilterType(value) {
      this.$store.commit('intentsList/setValue', {
        key: 'selectedFilterType',
        value,
      });
    },
    setSelectedFilterStatus(value) {
      this.$store.commit('intentsList/setValue', {
        key: 'selectedFilterStatus',
        value,
      });
    },
    setTableOptions(value) {
      if (!this.isTableUpdated) {
        this.isTableUpdated = true;
        return;
      }

      this.$store.commit('intentsList/setValue', {
        key: 'tableOptions',
        value,
      });
    },
    customSort: function (items, index, isDesc) {
      if (this.searchResults.length && this.usingSearchResults) {
        return items.sort((a, b) => {
          const aIndex = this.searchResults.indexOf(a.intent);
          const bIndex = this.searchResults.indexOf(b.intent);
          if (aIndex < bIndex) {
            return -1;
          }
          if (aIndex > bIndex) {
            return 1;
          }
          return 0;
        });
      }

      let desc = false;
      if (Array.isArray(isDesc) && isDesc.length === 1) {
        desc = isDesc[0];
      }

      if (
        Array.isArray(index) &&
        index.length === 1 &&
        index[0] === 'displayName'
      ) {
        // sort by ddisplayName
        items.sort((a, b) => {
          const aName = this.displayName(a.intent, this.user.language);
          const bName = this.displayName(b.intent, this.user.language);
          if (aName < bName) {
            return desc ? 1 : -1;
          }
          if (aName > bName) {
            return desc ? -1 : 1;
          }
          return 0;
        });
      }

      if (
        Array.isArray(index) &&
        index.length === 1 &&
        index[0] === 'relevanceFrequent'
      ) {
        // sort by relevanceFrequent
        items.sort((a, b) => {
          if (a.relevanceFrequent < b.relevanceFrequent) {
            return desc ? 1 : -1;
          }
          if (a.relevanceFrequent > b.relevanceFrequent) {
            return desc ? -1 : 1;
          }
          return 0;
        });
      }

      return items;
    },
    parseIntent(intent) {
      const newIntent = {
        ...intent,
        deployStatus: IntentUtils.getDeployStatus(intent),
        relevanceFrequent: intent?.relevance?.frequent || 0,
      };

      if (intent.state) {

        // clean state from unused channels
        const channels = Object.keys(intent.state);
        // clone intent state
        const state = { ...intent.state };
        channels.forEach((channel) => {
          if (!this.currentChannels.find((c) => c.channelId === channel)) {
            delete state[channel];
          }
        });




        const { status, forward } = IntentUtils.getStatus(
          state,
          this.selectedFilterChannel
        );

        if (status) newIntent.status = status;
        if (forward) {
          newIntent.forward = forward;
        }
      }

      if (intent.payload?.inReview) {
        newIntent.status = this.statusTypes.IN_REVIEW;
      }
      return newIntent;
    },
    changeFilterChannel(channelId) {
      this.setSelectedFilterChannel(channelId);
      this.trackIt('filter-select-channel', { selected: channelId });
    },
    changeFilterType(selectedItems) {
      this.setSelectedFilterType(selectedItems);
      this.trackIt('filter-chip-type', { selected: selectedItems });
    },
    changeFilterStatus(selectedItems) {
      this.setSelectedFilterStatus(selectedItems);
      this.trackIt('filter-chip-status', { selected: selectedItems });
    },
    selectIntent(selectedIntent) {
      this.selectedIntent = selectedIntent;
      this.$emit('onSelected', selectedIntent);
      this.trackIt('select-intent');
    },
    goToEdit(name) {
      if (!name) return;
      const intent = this.intent(name);
      if (this.isAdmin || (intent && !intent.suggested)) {
        this.$router.push({
          name: 'intentsedit',
          params: { name },
        });
      }
    },
    goToTraining(name) {
      if (!name || !this.isAdmin) return;
      this.$router.push({
        name: 'intent-view',
        params: { name, view: 'training' },
      });
    },
    openCreateDialog(intent) {
      this.isSuggestionDialogOpen = true;
      this.suggestedIntent = intent;
    },
    cutText(string) {
      const maxLength = 200;
      if (string.length > maxLength) {
        return string.slice(0, maxLength) + '...';
      }
      return string;
    },
    trackIt(type, payload) {
      TrackingService.trackIntentsView({
        botId: this.currentBotId,
        type,
        source: 'IntentsList',
        payload: payload,
      });
    },
    scrollToItem(intentId) {
      this.scrollToTarget = intentId;
      const el = this.$el.querySelector(`#${this.scrollToTarget}`);
      if (!el) return;
      el.scrollIntoView({ behavior: 'smooth' });

      // Reset if target was found
      this.scrollToTarget = null;
    },
    setCurrentPage(value) {
      this.$store.commit('intentsList/setValue', { key: 'currentPage', value });
    },
    intentIcon(intent) {
      switch (intent.template) {
        case 'SimpleInformation':
          // static editorial answer with buttons
          return 'article';
        case 'RAGTopic':
          return 'mdi-book-open-variant';
        case 'Smalltalk':
          return 'mdi-coffee';
      }

      return this.typeIcons[intent.type].icon;
    },
    /**
     * Handles keydown events on the AI search input field
     * @param {KeyboardEvent | {key: 'Enter'|string}} event
     * @returns {void | Promise<*>}
     */
    handleSearchKeyDown(event) {
      if (event.key !== 'Enter') return;
      if (!this.searchValue.length) {
        this.usingSearchResults = false;
        return;
      }

      if (!this.useAISearch) return this.performNormalSearch(this.searchValue);
      return this.performAISearch(this.searchValue);
    },
    /**
     * Handles clicking on the inner icon, which will either execute search or clear the search
     */
    handleSearchbarIconClick() {
      // Reset
      if (this.usingSearchResults) return this.clearSearch();

      // Execute search
      return this.handleSearchKeyDown({key: 'Enter'});
    },
    /**
     * Clears the search input field and restores the sorting/filtering to its original state
     */
    clearSearch() {
      this.usingSearchResults = false;
      this.searchValue = '';
      this.searchResults = [];
    },
    /**
     * Executes the AI search to filter/sort intents/topics
     * @param {string} searchTerm
     * @returns {Promise<*>}
     */
    async performAISearch(searchTerm) {
      console.log('performAISearch', searchTerm);
      this.isLoading = true;

      const results = await IntentService.getK2Indices({
        botId: this.currentBotId,
        text: searchTerm,
        // If not defined, it uses 10
        limit: 9_999,
      });
      console.log('results', results);
      if (results === false) return;

      // List of intent names, in the order of relevance
      this.searchResults = results.intents.map((r) => r.intent);
      this.usingSearchResults = true;

      this.isLoading = false;
    },
    performNormalSearch(searchTerm) {
      const results = this.fuse.search(searchTerm);

      this.searchResults = results.map((r) => this.allIntents[r.refIndex].intent);
      this.usingSearchResults = true;
    },
    enableAllTags() {
      /**
       * Popular Tags vs. All Tags does not use the same sort.
       * Keep track of which tags were enabled when we switch to showing all.
       */
      this.activeTagIndexes = this.activeTags.map(tag => this.allTags.indexOf(tag));
      this.showAllTags = true;
    },
  },
  watch: {
    useAISearch() {
      this.searchResults = [];
    },
    allIntents(newList) {
      this.fuse = new Fuse(newList, {
        keys: [
          {
            name: 'displayName',
            weight: 0.7,
          },
          {
            name: 'description',
            weight: 0.3,
          }
        ],
      });
    },
    allTags(newList) {
      if (newList.length > 9) return;
      this.enableAllTags();
    },
  }
};
</script>
<style scoped>
.justify-group-end :deep(.v-slide-group__content) {
  justify-content: end;
}
:deep(.v-data-table) .item-active {
  background: rgba(111, 170, 167, 0.1) !important;
}
:deep(.v-data-table) .item td:first-child {
  border-left: 4px solid transparent !important;
}
:deep(.v-data-table) .item-active td:first-child {
  border-left: 4px solid var(--v-secondary-darken2) !important;
}
:deep(i.v-data-table-header__icon) {
  vertical-align: initial;
}
.search-container {
  display: inline-flex;
}
/* If we display searchbar, we want a special width (18px = sort arrow icon width) */
.search-container:not(.w-fit) {
  width: calc(100% - 18px);
}
.inner-search-container {
  display: flex;
}
</style>
